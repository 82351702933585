import React, { useEffect, useRef, useState } from "react";
import { Mail, Smartphone } from "react-feather";
import { Credentials } from "../utils/Icons";
import {
  fetcher,
  getBrowserInfo,
  getUserDataFromCookie,
  setUserDataCookie,
} from "../utils/helper";
import OtpInput from "./PortalCredentials/child/OtpInput";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Loader } from "./common/Loader";

const TwoFactor = () => {
  const [twoFactor, setTwoFactor] = useState(true);
  const [animation, setAnimation] = useState(true);
  const [verificationType, setVerificationType] = useState("");
  const [recipient, setRecipient] = useState("");
  const [verifyAccount, setVerifyAccount] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const handleChangeOtp = (value, index) => {
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else if (otp[index] !== "") {
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  const checkUserDataIsAlreadySetInCookieOrNot = (browserInfo) => {
    let data = getUserDataFromCookie();
    if (data === null) {
      data = [];
      data.push({
        userId: sessionStorage.getItem("userId"),
        user: [
          {
            browserName: browserInfo.browser,
            system: browserInfo.os,
          },
        ],
      });
    } else if (data.userId == sessionStorage.getItem("userId")) {
      data.user.push({
        browserName: browserInfo.browser,
        system: browserInfo.os,
      });
    } else {
      data.push({
        userId: sessionStorage.getItem("userId"),
        user: [
          {
            browserName: browserInfo.browser,
            system: browserInfo.os,
          },
        ],
      });
    }
    setUserDataCookie(data);
  };

  const checkTwoFactorAuthenticated = () => {
    let browserInfo = getBrowserInfo();
    checkUserDataIsAlreadySetInCookieOrNot(browserInfo);
    if (sessionStorage.getItem("redirectUrl") !== null) {
      if (sessionStorage.getItem("redirectUrl").includes("signin")) {
        setTimeout(() => {
          navigate("/");
          sessionStorage.removeItem("redirectUrl");
        }, 2000);
      } else {
        setTimeout(() => {
          window.location.href = sessionStorage.getItem("redirectUrl");
          sessionStorage.removeItem("redirectUrl");
        }, 2000);
      }
    }
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  const handleSubmit = async () => {
    let valid = true;
    otp.forEach((item) => {
      if (item === "") {
        valid = false;
      }
    });

    if (!valid) {
      setError(true);
      return;
    } else {
      setError(false);
    }
    setLoadingConfirm(true);
    try {
      const res = await fetcher(
        "governify/customer/verifyOTP",
        "POST",
        JSON.stringify({
          code: otp.join(""),
          recipient: recipient,
        })
      );
      if (res.status) {
        setErrMsg("");
        setError(false);
        toast.success(res.message);
        checkTwoFactorAuthenticated();
      } else {
        setErrMsg(
          "This is a wrong or expired code. Try to resend another code."
        );
        setError(true);
      }
    } catch (error) {
      console.log("error");
    } finally {
      setLoadingConfirm(false);
    }
  };

  const sendVerificationMessage = async (type, value) => {
    try {
      const res = await fetcher(
        "governify/customer/sendOTP",
        "POST",
        JSON.stringify({ channel: type, recipient: value })
      );
      console.log("res", res);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    if(sessionStorage.getItem('token') == null || sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == '') {
      navigate("/signin");
    }
  },[])

  useEffect(() => {
    setTimeout(() => {
      setAnimation(false);
    }, 300);
  }, []);

  return (
    <>
      {loadingConfirm && <Loader />}
      <div
        className="d-flex flex-column justify-content-center align-items-center inc-auth-container"
        style={{ minHeight: "100vh" }}
      >
        <div className="animation-container" style={{ minHeight: "90px" }}>
          <div
            className={`header-heading1 ${
              animation ? "animation-content" : ""
            } ff-ws `}
            style={{
              transition: "transform 1s ease, opacity 2s ease",
              fontSize: "50px",
              fontWeight: "500",
            }}
          >
            Governify
          </div>
        </div>
        {twoFactor && (
          <div
            className="text-center d-flex flex-column p-4"
            style={{
              gap: "20px",
              maxWidth: "440px",
              boxShadow: "0 2px 6px #0003",
              background: "#fff",
            }}
          >
            <div>
              <Credentials width={56} height={56} fill="#00BF63" />
            </div>
            <div className="d-flex flex-column" style={{ gap: "5px" }}>
              <div className="fs-24 fw-bold" style={{ color: "#202223" }}>
                Two-Factor Authentication
              </div>
              <div className="fs-16" style={{ color: "#6D7175" }}>
                Select a Verification method to verify your Account.
              </div>
              <div
                style={{
                  border: "1px solid #DFDFDF",
                  padding: "18px",
                  gap: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                className="d-flex mt-2 align-items-center"
                onClick={() => {
                  setTwoFactor(false);
                  setVerifyAccount(true);
                  setVerificationType("email");
                  setRecipient(sessionStorage.getItem("userEmail"));
                  sendVerificationMessage(
                    "email",
                    sessionStorage.getItem("userEmail")
                  );
                }}
              >
                <Mail
                  style={{ color: "#00BF63", width: "32px", height: "24px" }}
                />
                <div
                  className="d-flex flex-column text-start"
                  style={{ gap: "5px" }}
                >
                  <div
                    className="fs-18 fw-semibold"
                    style={{ color: "#202223" }}
                  >
                    via Email
                  </div>
                  <div style={{ color: "#6D7175" }}>
                    Get a code at{" "}
                    {sessionStorage.getItem("userEmail")
                      ? sessionStorage.getItem("userEmail").slice(0, 3) +
                        "***************" +
                        sessionStorage
                          .getItem("userEmail")
                          .slice(
                            sessionStorage.getItem("userEmail").length - 9,
                            sessionStorage.getItem("userEmail").length
                          )
                      : ""}
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #DFDFDF",
                  padding: "18px",
                  gap: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                className="d-flex mt-2 align-items-center"
                onClick={() => {
                  setTwoFactor(false);
                  setVerifyAccount(true);
                  setVerificationType("sms");
                  setRecipient(sessionStorage.getItem("phoneNumber"));
                  sendVerificationMessage(
                    "sms",
                    sessionStorage.getItem("phoneNumber")
                  );
                }}
              >
                <Smartphone
                  style={{ color: "#00BF63", width: "32px", height: "24px" }}
                />
                <div
                  className="d-flex flex-column text-start"
                  style={{ gap: "5px" }}
                >
                  <div
                    className="fs-18 fw-semibold"
                    style={{ color: "#202223" }}
                  >
                    via Mobile Number
                  </div>
                  <div style={{ color: "#6D7175" }}>
                    Get a code at{" "}
                    {sessionStorage.getItem("phoneNumber")
                      ? sessionStorage.getItem("phoneNumber").slice(0, 3) +
                        "********" +
                        sessionStorage
                          .getItem("phoneNumber")
                          .slice(
                            sessionStorage.getItem("phoneNumber").length - 2,
                            sessionStorage.getItem("phoneNumber").length
                          )
                      : ""}
                  </div>
                </div>
                {/* <div
                className="fs-s"
                style={{ color: "#EF4444", marginLeft: "auto" }}
              >
                Unverified
              </div> */}
              </div>
            </div>
          </div>
        )}
        {verifyAccount && (
          <div
            className="d-flex flex-column align-items-center text-center p-4"
            style={{
              gap: "20px",
              maxWidth: "440px",
              boxShadow: "0 2px 6px #0003",
              background: "#fff",
            }}
          >
            {verificationType === "email" ? (
              <Mail
                style={{ color: "#00BF63", width: "48px", height: "36px" }}
              />
            ) : (
              <Smartphone
                style={{ color: "#00BF63", width: "48px", height: "36px" }}
              />
            )}
            <div className="d-flex flex-column" style={{ gap: "20px" }}>
              <div className="d-flex flex-column" style={{ gap: "5px" }}>
                <div className="fs-24 fw-bold" style={{ color: "#202223" }}>
                  Two-Factor Authentication
                </div>
                <div className="fs-16" style={{ color: "#6D7175" }}>
                  Enter your 6-digit code numbers sent to you at{" "}
                  {verificationType === "email"
                    ? sessionStorage.getItem("userEmail")
                    : sessionStorage.getItem("phoneNumber")}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                {Array(6)
                  .fill("")
                  .map((_, index) => {
                    return (
                      <OtpInput
                        otp={otp}
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        index={index}
                        setOtp={setOtp}
                        handleChangeOtp={handleChangeOtp}
                        handleKeyDown={handleKeyDown}
                        error={error}
                      />
                    );
                  })}
              </div>
              <div className="d-flex flex-column" style={{ gap: "5px" }}>
                {errMsg && <div style={{ color: "#EF4444" }}>{errMsg}</div>}
                {/* {successMsg && (
                <div style={{ color: "#059669" }}>{successMsg}</div>
              )} */}
                <div style={{ color: "#6D7175" }} className="fs-16">
                  Didn't receive a verification code?
                </div>
                <span
                  className="fs-16 fw-semibold"
                  style={{ color: "#059669", cursor: "pointer" }}
                  onClick={() => sendVerificationMessage(verificationType, recipient)}
                >
                  Resend Code
                </span>
              </div>
              <div>
                <button
                  style={{
                    background: "#00BF63",
                    color: "#fff",
                    borderRadius: "8px",
                    gap: "5px",
                    padding: "10px 20px",
                  }}
                  className={`border-0 d-flex fs-16 fw-semibold align-items-center w-100 justify-content-center`}
                  onClick={() => handleSubmit()}
                >
                  Verify
                </button>
              </div>
              <span
                className="fs-16 fw-semibold"
                style={{ color: "#059669", cursor: "pointer" }}
                onClick={() => {
                  setVerifyAccount(false);
                  setTwoFactor(true);
                }}
              >
                Try another method
              </span>
            </div>
          </div>
        )}
      </div>
      <ToastContainer position="bottom-right" className="custom-toast" />
    </>
  );
};

export default TwoFactor;
