import { Govern } from "../../assets/image";

export const Loader = ({upload = false}) => {
  return (
    <div id="loading-overlay" style={{display: 'flex', flexDirection: 'column'}}>
      <div className="spinner">
        <Govern />
      </div>
        {/* {upload && <div style={{color: '#fff', fontSize: '24px'}}>File is being uploaded. please wait...</div>} */}
    </div>
  );
};
